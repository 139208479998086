
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ScoreRating from '@/modules/common/components/ui-kit/score-rating.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import Range from '@/modules/common/components/ui-kit/range.vue';
import RankingBreakdown from '@/modules/ranking/components/ranking-breakdown.vue';
import type RankingService from '@/modules/ranking/ranking.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type UserService from '@/modules/user/user.service';
import type ProvidersService from '@/modules/providers/providers.service';
import type RankingHistoryService from '@/modules/common/modules/ranking-history/ranking-history.service';
import { Location } from 'vue-router';

@Component({
    components: {
        ProviderCard,
        RankingBreakdown,
        Range,
        ScoreRating,
    },
})
export default class RankingProviders extends Vue {
    @inject(KEY.RankingService) private rankingService!: RankingService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.RankingHistoryService) private rankingHistoryService!: RankingHistoryService;

    @Prop({ type: String })
    provider!: string;

    @Prop({ type: Boolean })
    skeleton!: boolean;

    readonly newReviews: number = 30;

    get maxScore() {
        return this.providersService.getMaxScore(this.provider) || 5;
    }

    get currentHotel() {
        return this.rankingService.getMainHotel(this.provider) || {
            reviews: 0,
            rating: 0,
            hotelName: '',
            ratingImproved: 0,
            link: '',
        };
    }

    get myHotelName(): string | null {
        if (!this.userService.currentHotelId) {
            return null;
        }
        return this.hotelsService.hotelNames[this.userService.currentHotelId];
    }

    get minMaxRatings() {
        return this.rankingService.getMinMaxCompetitiveRating(this.provider);
    }

    get minMaxReviews() {
        return this.rankingService.getMinMaxCompetitiveReview(this.provider);
    }

    get scoreMinScale() {
        if (this.currentHotel === null) {
            return 0;
        }

        const leftOffset = Math.min(this.scoreLowest, this.currentHotel.rating);
        return Math.max(Math.floor(leftOffset * 0.8), 0);
    }
    get scoreMaxScale() {
        if (this.currentHotel === null) {
            return 1;
        }

        const rightOffset = Math.max(this.scoreHighest, this.currentHotel.rating);
        return Math.min(Math.round(rightOffset * 1.4), this.maxScore);
    }

    get scoreLowest() {
        return this.minMaxRatings ? this.minMaxRatings[0] : 0;
    }
    get scoreHighest() {
        return this.minMaxRatings ? this.minMaxRatings[1] : 0;
    }

    get reviewMinScale() {
        if (this.currentHotel === null) {
            return 0;
        }

        const leftOffset = Math.min(this.reviewLowest, this.currentHotel.reviews);
        return Math.max(Math.floor(leftOffset * 0.3), 0);
    }
    get reviewMaxScale() {
        if (this.currentHotel === null) {
            return 1;
        }

        const rightOffset = Math.max(this.reviewHighest, this.currentHotel.reviews);
        return Math.max(Math.round(rightOffset * 1.3), 1);
    }
    get reviewLowest() {
        return this.minMaxReviews ? this.minMaxReviews[0] : 0;
    }
    get reviewHighest() {
        return this.minMaxReviews ? this.minMaxReviews[1] : 0;
    }

    get reviewHistoryRoute(): Location {
        return {
            name: `${this.$route.name}.ranking-history-popup`,
            params: {
                provider: this.provider,
            },
        };
    }

    openHistory() {
        if (this.provider && this.rankingService.data) {
            this.rankingHistoryService.resetLoading();
            this.$router.push(this.reviewHistoryRoute);
        }
    }
}
